import React, { useContext } from 'react';
import { Title, Text, Img, Img2, Img3, Img4, Inner, Item, Container, TitCont, ImgCont } from './styles/about';
import { Context } from '../../context'

export default function About({ children, direction = 'row', ...restProps }) {
    return (
        <Item {...restProps}>
            <Inner direction={direction}>{children}</Inner>
        </Item>
    )
}

About.Title = function AboutTitle({ children, ...restProps }) {
    const { useTheme } = useContext(Context)
    return <Title theme={useTheme} {...restProps}>{children}</Title>;
}

About.Text = function AboutText({ children, ...restProps }) {
    const { useTheme } = useContext(Context)
    return <Text theme={useTheme} {...restProps}>{children}</Text>;
}

About.Img = function AboutImg({ children, ...restProps }) {
    return <Img {...restProps}>{children}</Img>;
  }

About.Img2 = function AboutImg2({ children, ...restProps }) {
    return <Img2 {...restProps}>{children}</Img2>;
  }

About.Img3 = function AboutImg3({ children, ...restProps }) {
    return <Img3 {...restProps}>{children}</Img3>;
  }

About.Img4 = function AboutImg4({ children, ...restProps }) {
    return <Img4 {...restProps}>{children}</Img4>;
  }

About.Container = function AboutContainer({ children, ...restProps }) {
    return <Container {...restProps}>{children}</Container>;
}

About.TitCont = function AboutTitCont({ children, ...restProps }) {
    return <TitCont {...restProps}>{children}</TitCont>;
}

About.ImgCont = function AboutImgCont({ children, ...restProps }) {
    return <ImgCont {...restProps}>{children}</ImgCont>;
}
