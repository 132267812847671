import React, { useState, useEffect } from 'react';
import * as ROUTES from '../constants/routes';
import { Contact } from '../components';
import { CSSTransition } from "react-transition-group";

export function ContactContainer() {
  
  const [fadeIn, setFadeIn] = useState(false)

  useEffect((fadeIn) => {
    setFadeIn(!fadeIn)
  }, [setFadeIn, fadeIn])

    return (
      <CSSTransition
      in={fadeIn}
      classNames={'fade'}
      timeout={800}
    >
        <Contact.Container>
            <Contact.Pane>
              <Contact.Info>
              <Contact.Title>Drew Lane</Contact.Title>
              <Contact.Title>Email: drew@productionrigger.co.uk</Contact.Title>
              <Contact.Title>Phone: 07879 884599</Contact.Title>
              </Contact.Info>
              <Contact.LogoPane>
                  <Contact.LogoCont>
                    <a href={"https://www.facebook.com/ProductionRigger"}>
                        <Contact.Logos to={ROUTES.FACEBOOK} src={process.env.PUBLIC_URL + "content/facebook.jpg"} alt="b&W city picture" />
                    </a>
                  </ Contact.LogoCont>
                  < Contact.LogoCont>
                    <a href={"https://www.linkedin.com/in/drew-lane-6991a31a1"}>
                        < Contact.Logos to={ROUTES.LINKEDIN} src={process.env.PUBLIC_URL + "content/linkedin.jpg"} alt="b&W city picture" />
                    </a>
                  </ Contact.LogoCont>
                  < Contact.LogoCont>
                        <a href={"https://www.instagram.com/productionrigger/"}>
                        < Contact.Logos src={process.env.PUBLIC_URL + "content/instagram.jpg"} alt="b&W city picture" />
                        </a>
                  </ Contact.LogoCont>
              </Contact.LogoPane>
            </Contact.Pane>
        </Contact.Container>
    </CSSTransition>
    )
}
