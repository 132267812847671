import React, { useContext, useEffect } from 'react';
import { NavContainer } from '../containers/nav';
import { PicsContainer } from '../containers/pics';
import { PicsContainer2 } from '../containers/pic2';
import { AboutContainer } from '../containers/about';
import { FootContainer } from '../containers/foot';
import { Grid } from '../components';
import { Context } from '../context'

export default function About() {

  const { setAbout, setHome, setContact, setServ, inUse } = useContext(Context)

  const arrayClear = () => {
    inUse.splice(0, inUse.length)
};
arrayClear()

  useEffect(() => {
    setAbout(true)
    setHome(false)
    setContact(false)
    setServ(false)
  }, [setAbout, setHome, setContact, setServ ])


    return (
      <Grid.Container>
          <Grid.Nav>
            <NavContainer />
          </ Grid.Nav>
        <Grid.Content>
          <AboutContainer />
        </ Grid.Content>
      <Grid.Pics>
        <PicsContainer show="150" />
      </ Grid.Pics>
      <Grid.Pic2>
        <PicsContainer2 show="about" />
      </ Grid.Pic2>
      <Grid.Foot>
        <FootContainer />
      </Grid.Foot>
    </ Grid.Container>
      )
}
