import styled from 'styled-components';

export const Item = styled.div`
height: 3vh;
`;

export const Inner = styled.div`
height: 100%;
`;

export const Container = styled.section`
  width: 100%;
  height: 100%;
  align-items: center;
  background: #292929;
`;


export const Pane = styled.div`
    width: 20%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


export const LogoCont = styled.div`
    object-fit: cover;
    width: 20%;

`;

export const Logos = styled.img`
  object-fit: cover;
  width: 100%
`;
