import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import * as ROUTES from '../constants/routes';
import { Nav } from '../components';
import { Context } from '../context'

export function NavContainer() {

const { home, about, contact, serv, setSide } = useContext(Context)
const [open, setOpen] = useState(false)
const [size, setSize] = useState([0, 0]);

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  useWindowSize()


useEffect(() => {
        open? 
        setSide('translateX(0rem)')
        :
        setSide('translateX(-17rem)')
      }, [setSide, open])


  return (
          <>
        {size[0] <= size[1] ?
        <Nav>
        <Nav.Burg onClick={() => setOpen(!open)}>&#9776;</Nav.Burg>
      <Nav.Container>
                      <Nav.Link to={ROUTES.HOME} size={home} >
                                <Nav.ImgCont size={serv}>
                                      <Nav.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                                </ Nav.ImgCont>
                                <Nav.Ltext size={home}>Home</Nav.Ltext>
                      </ Nav.Link>

                      <Nav.Link to={ROUTES.ABOUT} size={about} >
                                <Nav.ImgCont size={serv}>
                                        <Nav.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                                </ Nav.ImgCont>
                                <Nav.Ltext size={about}>About</Nav.Ltext>
                      </ Nav.Link>

                      <Nav.Link to={ROUTES.CONTACT} size={contact} >
                                <Nav.ImgCont size={serv}>
                                        <Nav.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                                </ Nav.ImgCont>
                                <Nav.Ltext size={contact} place="contact">Contact</Nav.Ltext>
                      </ Nav.Link>

                      <Nav.Link to={ROUTES.PORT} size={serv} >
                                <Nav.ImgCont size={serv}>
                                          <Nav.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                                </ Nav.ImgCont>
                                <Nav.Ltext size={serv} place="serv">Credits</Nav.Ltext>
                      </ Nav.Link>

      </Nav.Container>
      </Nav>
        :
        <Nav.Container>
                      <Nav.Link to={ROUTES.HOME} size={home} >
                                <Nav.ImgCont size={serv}>
                                      <Nav.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                                </ Nav.ImgCont>
                                <Nav.Ltext size={home}>Home</Nav.Ltext>
                      </ Nav.Link>

                      <Nav.Link to={ROUTES.ABOUT} size={about} >
                                <Nav.ImgCont size={serv}>
                                        <Nav.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                                </ Nav.ImgCont>
                                <Nav.Ltext size={about}>About</Nav.Ltext>
                      </ Nav.Link>

                      <Nav.Link to={ROUTES.CONTACT} size={contact} >
                                <Nav.ImgCont size={serv}>
                                        <Nav.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                                </ Nav.ImgCont>
                                <Nav.Ltext size={contact} place="contact">Contact</Nav.Ltext>
                      </ Nav.Link>

                      <Nav.Link to={ROUTES.PORT} size={serv} >
                                <Nav.ImgCont size={serv}>
                                          <Nav.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                                </ Nav.ImgCont>
                                <Nav.Ltext size={serv} place="serv">Credits</Nav.Ltext>
                      </ Nav.Link>

      </Nav.Container>}
  </>
  )
}
