import styled from 'styled-components';

export const Item = styled.div`

`;

export const Inner = styled.div`

`;

export const Container = styled.div`
height: 650vh;

@media (max-width: 500px){
  height: 650vh;
}


@media (min-width: 1000px) {
  height:500vh;
}

@media (max-width: 1250px) and (orientation : landscape){
    height: 1620vh;
}



@media (min-width: 1200px){
    height: 540vh;
}

@media (min-width: 1600px){
    height: 460vh;
}

@media (min-width: 2200px) and (max-height: 1300) {
  height: 750vh;
  }

&.fade-enter {
    opacity: 0;
}
&.fade-enter.fade-enter-active {
    opacity: .5;
    transition: opacity 800ms ease-in;
}
&.fade-enter-done{
  opacity: 1;
}
&.fade-exit {
    opacity: 1;
}

&.fade-exit.fade-exit-active {
    opacity: 0.5;
    transition: opacity 800ms ease-in;
}
&.fade-exit-done{
  opacity 0;
}
`;

export const Title = styled.h2`
margin: 0 auto 2em auto;;
padding: 0;
width: 60%;
font-size: 1.7rem;
text-align: center;

@media (min-width: 700px) {
    font-size: 2.5rem;
}

@media (min-width: 1000px){
    font-size: 3rem;
    width: 55%
}

@media (min-width: 1250px) {
    width: 20%;
}

@media (min-width: 1400px) {
    width: 45%;
    font-size: 3.5rem;
}

@media (min-width: 1600px){
    width: 40%;
    font-size: 3.7rem;
}

@media (min-width: 1900px) {
  width: 35%;
}

@media (min-width: 2500px) {
  width: 30%;
  font-size: 4.2rem;
}

@media (min-width: 3000px) {
    width: 50%;
  }

  @media (min-width: 2000px) and (max-height: 1200px){
    width: 28%;
  }
`;

export const Pane = styled.div`
width: 100%
margin: 0;
padding: 0;

@media (min-width: 1250px) {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: auto;
}
`;

export const Section = styled.section`
width: 87%;
margin: 0;
`;

export const SubTitle = styled.h3`
margin: 2em auto 2em auto;
width: 100%;
height: 3rem;
font-size: 1.5rem;
text-align: center;

@media (min-width: 700px) {
    font-size: 2.3rem;
}

@media (min-width: 1250px) {
width: 25%;
font-size: 2rem;
height: 4rem;
}

@media (min-width: 1400px) {
    width: 45%;
    height: 6rem;
    font-size: 3rem;
}

@media (min-width: 2500px) {
  font-size: 3.5rem;
}
`;

export const List = styled.ol`
width: 100%;
padding-bottom: 2.5em;

@media (max-width: 1300px){
 border-bottom: 8px solid #222;
}
`;

export const LI = styled.li`
display: flex;
justify-content: space-between;
width: 100%;
padding: 0;
margin: 0;
margin-top: 2em;

@media (min-width: 1250px){
    width: 60%;
    margin: auto;
}

@media (min-width: 1400px) {
    width: 80%;
    margin-top: 2em;
}
`;

export const ImgCont = styled.div`
object-fit: cover;
width: 10%;

@media (min-width: 1250px) {
width: 10%;
}

`;

export const Img = styled.img`
object-fit: cover;
width: 100%
`;

export const Ltext = styled.p`
color: #f8f8ff;
font-size: 1rem;
margin-left: 1em;
margin-top: 0;
align-self: center;
width: 80%;

@media (min-width: 700px){
    font-size: 1.5rem;
}

@media (min-width: 1250px) {
    margin-left: 1.5em;
    font-size: 1.3rem;
}

@media (min-width: 1900px) {
  font-size: 1.5rem;
}

@media (min-width: 2500px) {
  font-size: 2rem;
}
`;