import React, { useContext } from 'react';
import { Ltext, Inner, Item, Container, Link, Img, ImgCont, Burg } from './styles/nav';
import { Context } from '../../context'


export default function Nav({ children, direction = 'column', ...restProps }) {

return (
    <Item {...restProps}>
        <Inner direction={direction}>{children}</Inner>
    </Item>
  )
}

Nav.Container = function NavContainer({ children, ...restProps }) {
    const { side } = useContext(Context)
    return <Container state={side} {...restProps}>{children}</Container>;
}

Nav.Ltext = function NavLtext({ children, ...restProps }) {
    return <Ltext {...restProps}>{children}</Ltext>;
}

Nav.Link = function NavLink ({ children, ...restProps }) {
    return <Link {...restProps}>{children}</ Link>
}

Nav.Img = function NavImg ({ children, ...restProps }) {
    return <Img {...restProps}>{children}</ Img>
}

Nav.ImgCont = function NavImgCont ({ children, ...restProps }) {
    return <ImgCont {...restProps}>{children}</ ImgCont>
}

Nav.Burg = function NavBurg ({ children, ...restProps }) {
    return <Burg {...restProps}>{children}</ Burg>
}
