import React, { useEffect, useState, useContext } from 'react';
import { About } from '../components';
import { CSSTransition } from "react-transition-group";
import { Context } from '../context';

export function AboutContainer() {
  const { setHeight } = useContext(Context)
  const [fadeIn, setFadeIn] = useState(false)

  useEffect((fadeIn) => {
    setFadeIn(!fadeIn)
    setHeight(`${Math.floor(document.getElementById('aboutCont').offsetHeight)}px`)
  }, [setFadeIn, fadeIn, setHeight])

    return (
      <CSSTransition
        in={fadeIn}
        classNames={'fade'}
        timeout={800}
      >
        <About.Container id="aboutCont">
            <About.TitCont>
            <About.Img src={process.env.PUBLIC_URL + "content/drew.jpeg"} alt="b&W city picture" />
            <About.Title>Drew Lane<br/>
                          Production Rigger
            </About.Title>
            </About.TitCont>
            <About.Text>Whilst still at school, Drew spent his evenings and weekends obtaining valuable industry experience, 
                        and went on to study a B-Tech in Performance Technology. Fast forward may years later, He now has an 
                        abundance of qualifications and a metric ton of experience specific to the event rigging industry ranging 
                        from Theatre to Film, Exhibition to Concerts and everything else in between.<br/><br/>

                        Drew has significant industry knowledge being NRC level 2 qualified, with Working at height, IPAF, PASMA, 
                        Kinesys and Authorized service engineer stripes for the industry's leading motor and accessory providers, 
                        its like looking at a deck of cards. With this in his armoury, Drew is a hive of rigging knowledge whether 
                        as part of your rigging team or leading them into battle.<br/><br/>

                        Taking your initial rigging concepts whether ground supported, climbing tower systems or fully flown rigs, 
                        through the process of kit specification, cad drawings, weight loading's and even load monitoring during 
                        rehearsals. Through to rigging it and seeing your show become reality and a success. Not only is Drew an 
                        asset when it comes to putting in a production, his skillset allows him to further maintain and solve 
                        problems whilst onsite. Whether in Hotels, Theatre’s or Arenas all aspects will have been gone over with a 
                        fine tooth comb.<br/><br/>

                        “YOU’RE IN SAFE HANDS”
            </About.Text>
            <About.ImgCont>
            <About.Img2 src={process.env.PUBLIC_URL + "content/drewB&W.jpg"} alt="b&W city picture" />
            <About.Img3 src={process.env.PUBLIC_URL + "content/pic15.jpeg"} alt="b&W city picture" />
            <About.Img4 src={process.env.PUBLIC_URL + "content/pic28.jpeg"} alt="b&W city picture" />
            </About.ImgCont>
        </About.Container>
    </CSSTransition>
    )
}
