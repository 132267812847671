import React from 'react';
import { Nav, Content, Inner, Item, Container, Pics, Pic2,
     Container2, Foot, Img, Home, Title, LogoPane } from './styles/grid';


export default function Grid({ children, ...restProps }) {

    return (
        <Item {...restProps}>
            <Inner>{children}</Inner>
        </Item>
    )
}

Grid.Container = function GridContainer({ children, ...restProps }) {
      return <Container {...restProps}>{children}</Container>;
  }

  Grid.Container2 = function GridContainer2({ children, ...restProps }) {
        return <Container2 {...restProps}>{children}</Container2>;
    }


Grid.Nav = function GridNav({ children, ...restProps }) {
    return <Nav {...restProps}>{children}</Nav>;
}

Grid.Content = function GridContent({ children, ...restProps }) {
    return <Content {...restProps}>{children}</Content>;
}

Grid.Pics = function GridPics({ children, ...restProps }) {
    return <Pics {...restProps}>{children}</Pics>;
}

Grid.Pic2 = function GridPic2({ children, ...restProps }) {
    return <Pic2 {...restProps}>{children}</Pic2>;
}

Grid.Foot = function GridFoot({ children, ...restProps }) {
    return <Foot {...restProps}>{children}</Foot>;
}

Grid.Img = function GridImg({ children, ...restProps }) {
    return <Img {...restProps}>{children}</Img>;
}

Grid.Home = function GridHome({ children, ...restProps }) {
    return <Home {...restProps}>{children}</Home>;
}

Grid.Title = function GridTitle({ children, ...restProps }) {
    return <Title {...restProps}>{children}</Title>;
}


Grid.LogoPane = function GridLogoPane({ children, ...restProps }) {
    return <LogoPane {...restProps}>{children}</LogoPane>;
}
