import styled from 'styled-components';

export const Item = styled.div`

`;

export const Inner = styled.div`

`;

export const Container = styled.section`
display: grid;
grid-template-columns: 10% 80% 10%;
grid-template-areas:
"nav nav nav"
"pics content pic2";

&.fade-enter {
    opacity: 0;
}
&.fade-enter.fade-enter-active {
    opacity: .5;
    transition: opacity 800ms ease-in;
}
&.fade-enter-done{
  opacity: 1;
}
&.fade-exit {
    opacity: 1;
}

&.fade-exit.fade-exit-active {
    opacity: 0.5;
    transition: opacity 800ms ease-in;
}
&.fade-exit-done{
  opacity 0;
}
`;

export const Container2 = styled.section`
height: 100vh;
display: grid;
grid-template-columns: 10% 80% 10%;
grid-template-rows: 5% 95%;
grid-template-areas:
"nav nav nav"
"content content content";
`;

export const Nav = styled.div`
grid-area: nav;
`;

export const Content = styled.div`
grid-area: content;
height: 100%;
margin-top: 10%;
padding: 0;
display: flex;
flex-direction: column;

@media (min-width: 1500px){
    margin-top: 5%;
    }
`;

export const Pics = styled.div`
grid-area: pics;
justify-self: end;
`;

export const Pic2 = styled.div`
grid-area: pic2;
justify-self: start;
`;

export const Foot = styled.div`
grid-area: foot;
margin-bottom: 0;
`;

export const Img = styled.img`
width: 60%;
align-self: center;

@media (max-width: 1000px) and (orientation: landscape){
    width: 40%;
}

@media (min-width: 1300px){
 width: 35%;
}

@media (min-width: 1700px){
    width: 40%;
}
  
@media (min-width: 2000px){
  width: 30%;
}
`;

export const Home = styled.div`
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
    @media (min-width: 1400px){
      height: 75vh;
    }


    @media (max-width: 1200px) and (orientation: landscape){
    height: 150vh;
      }
`;

export const Title = styled.h1`
    font-size: .8rem;
    text-align: center;
    margin-top: 1em;
    padding-top: 0;
    letter-spacing: .1em;
    text-transform: uppercase;

    @media (max-width: 1000px) and (orientation: landscape){
        font-size: 1rem;
        margin-top: 1.5em;
        letter-spacing: .2em;
      }
  
      @media (min-width: 750px) {
        font-size: 1rem;
        }
  
      @media (min-width: 1300px){
        font-size: 1.8rem;
        letter-spacing: .2em;
      }
  
      @media (min-width: 2000px){
        font-size: 4rem;
      }
`;

export const LogoPane = styled.div`
    width: 45%;
    margin: 0 auto 0 auto;
    padding-bottom: 4em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1000px) and (orientation: landscape){
      width: 20%;
      padding-bottom: .5em;
    }

    @media (min-width: 750px) {
      margin: 0 auto 0 auto;
    }

    @media (min-width: 1400px){
        width: 20%;
        height: 10%;
        margin: 0 auto 0 auto;
        padding-bottom: 0;
    }

    @media (min-width: 2000px){
        width: 15%;
        height: 10%;
        margin: 0 auto 0 auto;
    }
`;