import React, { useState } from "react"

const Context = React.createContext()

function ContextProvider({children}) {
  const [about, setAbout] = useState(false)
  const [home, setHome] = useState(false)
  const [contact, setContact] = useState(false)
  const [serv, setServ] = useState(false)
  const [side, setSide] = useState('translateX(-17rem)')
  const [height, setHeight] = useState('0px');
  const inUse = []
  


    return (
        <Context.Provider value={{
            home,
            setHome,
            about,
            setAbout,
            contact,
            setContact,
            serv,
            setServ,
            side,
            setSide,
            height,
            setHeight,
            inUse
        }}>
            {children}
        </Context.Provider>
    )
}

export {ContextProvider, Context}
