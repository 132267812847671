import styled, { css } from 'styled-components';
import { Link as ReachRouterLink } from 'react-router-dom';

export const Item = styled.div`

`;

export const Inner = styled.div`

`;

export const Container = styled.section`
  width: 100%;
  display: flex;
  z-index: 5;

  @media (max-width: 1250px) and (orientation: portrait) {
    position: fixed;
    transition: all 0.5s;
    transform: ${({state}) => state};
    width: 12rem;
    height: 100%;
    flex-direction: column;
    background-color: #000000;
  }

  @media (min-width: 1250px){
    justify-content: space-around;
  }
`;

export const Link = styled(ReachRouterLink)`
  padding: 0;
  margin-top: 1.5em;
  max-width: 30%
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 1.5em;

  
  @media (min-width: 1250px){
   max-width: 22%;
   margin: 0;
  }

  ${props => props.size === true && css`
         max-width:100%;
         margin-top: 2em;
         `}

  &:visited{
    color: #F40D17;
  }
  &:link{
    color: #F40D17;
  }
  &:active{
    color: #F40D17;
  }
  &:hover{
    color: #F40D17;
    ${props => props.size === false && css`
           border-bottom: #F40D17 solid 3px;
           `}
  }
`;


export const Pane = styled.div`
    width: 60%;
`;


export const Burg = styled.div`
font-size: 2rem;
margin-left: .5em;
margin-top: .5em;
`;


export const Ltext = styled.h2`
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    margin-left: .5em;
    
    ${props => props.place === "contact" && css`
    font-size: 20px;
   `}

     ${props => props.place === "serv" && css`
            font-size: 20px;
            `}

    ${props => props.size === true && css`
           font-size: 32px;
           `}
    ${props => props.size === true && props.place === "serv" && css`
            font-size: 31px;
            `}
    ${props => props.size === true && props.place === "contact" && css`
            font-size: 33px;
            `}

@media(min-width: 1250px) {
     font-size: 30px;    
     ${props => props.place === "contact" && css`
            font-size: 25px;
            `}
            ${props => props.place === "serv" && css`
                   font-size: 25px;
                   `}

            ${props => props.size === true && css`
                    font-size: 48px;
                    `}
            ${props => props.size === true && props.place === "serv" && css`
                    font-size: 47px;
                    `}
            ${props => props.size === true && props.place === "contact" && css`
                    font-size: 45px;
                    `}
    }
    &:visited{
      color: #F40D17;
    }
    &:link{
      color: #F40D17;
    }
    &:active{
      color: #F40D17;
    }

    @media (min-width: 2000px) {
        font-size: 40px;
        ${props => props.size === true && css`
               font-size: 50px;
               `}
    }
`;


export const ImgCont = styled.div`
    object-fit: cover;
    width: 15%;

`;

export const Img = styled.img`
  object-fit: cover;
  width: 100%
`;
