import React, { useState, useLayoutEffect }  from 'react';
import { Pics } from '../components';

export function PicsContainer({...restProps}) {

    const inUse = []
    const [size, setSize] = useState([10,10]);

    const PicsA = [
    "pic1", "pic2", "pic3", "pic4", "pic5", "pic6", "pic7", "pic8", "pic9",
    "pic10", "pic11", "pic12", "pic13", "pic14", "pic15", "pic16", "pic17", "pic18", "pic19",
    "pic20", "pic21", "pic22", "pic23", "pic24", "pic25", "pic26", "pic27", "pic28", "pic29", 
    "pic30",
]


      useLayoutEffect(() => {
        const updateSize = () => {
          setSize([document.body.offsetWidth, document.body.offsetHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);

    
function makeArray () {
    for (let i=0; i <= (size[1] / size[0] * 25); i++){
        inUse.push(PicsA[Math.floor(Math.random() * PicsA.length)])
    }
}
makeArray()


    return (
        <Pics.Container>
            {inUse.map(item =>
            <Pics.Pane key={Math.random()}>
                <Pics.Img src={process.env.PUBLIC_URL + `content/${item}.jpeg`} alt="b&W city picture" />
                </ Pics.Pane>
                )}
        </Pics.Container>
    )
}
