import React from 'react';
import { Logos, Inner, Item, Container, Pane, LogoCont } from './styles/foot';


export default function Foot({ children, ...restProps }) {

return (
    <Item {...restProps}>
        <Inner>{children}</Inner>
    </Item>
  )
}

Foot.Container = function NavContainer({ children, ...restProps }) {
    return <Container {...restProps}>{children}</Container>;
}

Foot.Pane = function FootPane({ children, ...restProps }) {
    return <Pane {...restProps}>{children}</Pane>;
}

Foot.Logos = function FootLogos ({ children, ...restProps }) {
    return <Logos {...restProps}>{children}</ Logos>
}

Foot.LogoCont = function FootLogoCont ({ children, ...restProps }) {
    return <LogoCont {...restProps}>{children}</ LogoCont>
}
