import { createGlobalStyle } from 'styled-components';


export const GlobalStyles = createGlobalStyle`
    html, body {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background:#000000;
        color: #F40D17;
        font-size: 16px;
        margin: 0;
    }
`;
