import styled from 'styled-components';


export const Inner = styled.div`

`;


export const Item = styled.div`

`;


export const Container = styled.section`
  width: 100%;
    height: 100vh;

  @media (min-width: 1000px) {
    height:100vh;
  }

  @media (orientation : landscape){
      height: 320vh;
  }

  @media (min-width: 1200px) and (orientation : portrait){
      height: 100vh;
  }

  @media (min-width: 1250px){
      height: 200vh;
  }
 
  @media (min-width: 2200px) and (max-height: 1300) {
    height: 400vh;
    }


      &.fade-enter {
          opacity: 0;
      }
      &.fade-enter.fade-enter-active {
          opacity: .5;
          transition: opacity 800ms ease-in;
      }
      &.fade-enter-done{
        opacity: 1;
      }
      &.fade-exit {
          opacity: 1;
      }

      &.fade-exit.fade-exit-active {
          opacity: 0.5;
          transition: opacity 800ms ease-in;
      }
      &.fade-exit-done{
        opacity 0;
      }

`;

export const TitCont = styled.div`
display: flex;
object-fit: cover;
`

export const Title = styled.h1`
    grid-area: Title;
    font-size: 1.4rem;
    line-height: 1.1;
    padding: 0 2em;
    border-radius: 0 50px 50px 0;

    @media (min-width: 700px) {
        font-size: 1.9rem;
    }

    @media (min-width: 1000px) {
        font-size: 2.3rem;
    }

    @media (min-width: 1300px) {
        font-size: 3rem;
    }

    @media (min-width: 1600px) {
        font-size: 3.5rem;
    }

    @media (min-width: 1900px) {
        font-size: 4rem;
    }

    @media (min-width: 2500px) {
        font-size: 5.5rem;
    }

    @media (min-width: 2000px) and (max-height: 1200px){
      font-size: 5.5rem;
    }

`;

export const Img = styled.img`
    width: 35%;
    object-fit: cover;
    margin-left: 2em;
    align-self: flex-start;
`;


export const ImgCont = styled.div`
display: flex;
justify-content: space-around;
padding-bottom: 5em;
object-fit: cover;
`

export const Img2 = styled.img`
    width: 30%;
    height: 100%;
    padding-top: 10%;
    grid-area: Img2;
    object-fit: cover;
`;

export const Img3 = styled.img`
    width: 30%;
    height: 100%;
    padding-top: 10%;
    grid-area: Img3;
    object-fit: cover;
`;

export const Img4 = styled.img`
    width: 30%;
    height: 100%;
    padding-top: 10%;
    grid-area: Img4;
    object-fit: cover;
`;

export const Text = styled.h3`
    grid-area: Text;
    padding: 0 2em;
    margin-top: 2em;
    font-size: .8rem;
    font-weight: normal;
    line-height: normal;
    color: #E8E8E8;

    @media (min-width: 1250px) {
        font-size: 1.4rem;
    }

    @media (min-width: 1600px) {
        font-size: 1.7rem;
    }
`;
