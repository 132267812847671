import React, { useContext } from 'react';
import { Img, Inner, Item, Container, Pane } from './styles/pics';
import { Context } from '../../context'


export default function Pics({ children, ...restProps }) {
    return (
        <Item {...restProps}>
            <Inner>{children}</Inner>
        </Item>
    )
}

Pics.Img = function PicsImg({ children, ...restProps }) {
    return <Img {...restProps}>{children}</Img>;
  }

Pics.Container = function PicsContainer({ children, ...restProps }) {
    const { height } = useContext(Context)
        return <Container height={height} {...restProps}>{children}</Container>;
    }

Pics.Pane = function PicsPane({ children, ...restProps }) {
        return <Pane {...restProps}>{children}</Pane>;
    }
