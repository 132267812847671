import React from 'react';
import * as ROUTES from '../constants/routes';
import { Foot } from '../components';

export function FootContainer() {


  return (
      <Foot.Container>
            <Foot.Pane>
                <Foot.LogoCont>
                      <Foot.Logos to={ROUTES.FACEBOOK} src={process.env.PUBLIC_URL + "content/facebook.jpg"} alt="b&W city picture" />
                </ Foot.LogoCont>
                < Foot.LogoCont>
                      < Foot.Logos to={ROUTES.LINKEDIN} src={process.env.PUBLIC_URL + "content/linkedin.jpg"} alt="b&W city picture" />
                </ Foot.LogoCont>
                < Foot.LogoCont>
                      < Foot.Logos to={ROUTES.TWITTER} src={process.env.PUBLIC_URL + "content/twitter.jpg"} alt="b&W city picture" />
                </ Foot.LogoCont>
                < Foot.LogoCont>
                      < Foot.Logos to={ROUTES.INSTAGRAM} src={process.env.PUBLIC_URL + "content/instagram.jpg"} alt="b&W city picture" />
                </ Foot.LogoCont>
            </Foot.Pane>
      </Foot.Container>
  )
}
