import React, { useContext, useEffect } from 'react';
import { Contact } from '../components';
import { NavContainer } from '../containers/nav';
import { FootContainer } from '../containers/foot';
import { Grid } from '../components';
import { Context } from '../context';

export default function Home() {

const { setAbout, setHome, setContact, setServ } = useContext(Context)


  useEffect(() => {
    setHome(true)
    setAbout(false)
    setContact(false)
    setServ(false)  
  }, [setHome, setAbout, setContact, setServ])


    return (
      <Grid.Container2>
          <Grid.Nav>
            <NavContainer />
          </ Grid.Nav>
          <Grid.Content>
          <Grid.Home>
            <Grid.Title> Drew Lane: Freelance Production Rigger </Grid.Title>
            <Grid.Img src={process.env.PUBLIC_URL + "content/logo.jpg"} alt="drew" />
            <Grid.LogoPane>
                  <Contact.LogoCont>
                    <a href={"https://www.facebook.com/ProductionRigger"}>
                        <Contact.Logos src={process.env.PUBLIC_URL + "content/facebook.jpg"} alt="b&W city picture" />
                    </a>
                  </ Contact.LogoCont>
                  < Contact.LogoCont>
                    <a href={"https://www.linkedin.com/in/drew-lane-6991a31a1"}>
                        < Contact.Logos src={process.env.PUBLIC_URL + "content/linkedin.jpg"} alt="b&W city picture" />
                    </a>
                  </ Contact.LogoCont>
                  < Contact.LogoCont>
                        <a href={"https://www.instagram.com/productionrigger/"}>
                        < Contact.Logos src={process.env.PUBLIC_URL + "content/instagram.jpg"} alt="b&W city picture" />
                        </a>
                  </ Contact.LogoCont>
              </Grid.LogoPane>
              </Grid.Home>
          </ Grid.Content>
          <Grid.Foot>
            <FootContainer />
          </Grid.Foot>
      </ Grid.Container2>
      )
}
