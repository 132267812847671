import React from 'react';
import Home from './pages/home';
import Port from './pages/port';
import About from './pages/about';
import Contact from './pages/Contact';
import * as ROUTES from './constants/routes';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


function App() {
  return (
    <Router>
                   <Switch>
                        <Route exact path={ROUTES.HOME}>
                            <Home />
                        </Route>
                        <Route exact path={ROUTES.ABOUT}>
                            <About />
                        </Route>
                        <Route exact path={ROUTES.PORT}>
                            <Port />
                        </Route>
                        <Route exact path={ROUTES.CONTACT}>
                            <Contact />
                        </Route>
                        </Switch>
    </Router>
  );
}


export default App;
