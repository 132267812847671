import React from 'react';
import {
    Container,
    Title,
    Pane,
    LogoPane,
    Logos,
    LogoCont,
    Info
} from './styles/contact';

export default function Contact({ children, ...restProps }) {
    return <Container {...restProps}>{children}</Container>;
}

Contact.Container = function ContactContainer({ children, ...restProps }) {
    return <Container img={process.env.PUBLIC_URL + "content/grid.jpg"} {...restProps}>{children}</Container>;
}

Contact.Pane = function ContactPane({ children, ...restProps }) {
    return <Pane {...restProps}>{children}</Pane>;
}

Contact.Title = function ContactTitle({ children, ...restProps }) {
    return <Title  {...restProps}>{children}</Title>;
}

Contact.LogoPane = function ContactLogoPane({ children, ...restProps }) {
    return <LogoPane {...restProps}>{children}</LogoPane>;
}

Contact.Logos = function ContactLogos ({ children, ...restProps }) {
    return <Logos {...restProps}>{children}</ Logos>
}

Contact.LogoCont = function ContactLogoCont ({ children, ...restProps }) {
    return <LogoCont {...restProps}>{children}</ LogoCont>
}

Contact.Info = function ContactInfo ({ children, ...restProps }) {
    return <Info {...restProps}>{children}</ Info>
}
