import React, { useContext, useEffect } from 'react';
import { NavContainer } from '../containers/nav';
import { HighlightsContainer } from '../containers/highlights';
import { Grid } from '../components';
import { Context } from '../context';
import { PicsContainer } from '../containers/pics';
import { PicsContainer2 } from '../containers/pic2';
import { FootContainer } from '../containers/foot';

export default function Port() {

const { setAbout, setHome, setContact, setServ } = useContext(Context)

  useEffect(() => {
    setServ(true)
    setHome(false)
    setContact(false)
    setAbout(false)
  }, [setServ, setHome, setContact, setAbout ])


    return (
      <Grid.Container>
          <Grid.Nav>
            <NavContainer />
          </ Grid.Nav>
          <Grid.Content>
            <HighlightsContainer />
          </Grid.Content>
          <Grid.Pics>
            <PicsContainer />
          </ Grid.Pics>
          <Grid.Pic2>
            <PicsContainer2 />
          </ Grid.Pic2>
          <Grid.Foot>
            <FootContainer />
          </Grid.Foot>
      </ Grid.Container>
      )
}
