import styled from 'styled-components';

export const Container = styled.section`
    background-image: url(${props => props.img});
    background-position: center;
    background-size: cover;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    padding-top: 10em;

    @media (max-width: 1000px) and (orientation: landscape){
      height: 200vh;
    }

    @media (max-width: 1400px) and (min-width: 1200px){
      height: 130vh
    }

    @media (min-width: 2000px) and (max-height: 1200px){
      height: 110vh
    }

    &.fade-enter {
      opacity: 0;
  }
  &.fade-enter.fade-enter-active {
      opacity: .5;
      transition: opacity 800ms ease-in;
  }
  &.fade-enter-done{
    opacity: 1;
  }
  &.fade-exit {
      opacity: 1;
  }

  &.fade-exit.fade-exit-active {
      opacity: 0.5;
      transition: opacity 800ms ease-in;
  }
  &.fade-exit-done{
    opacity 0;
  }
`;

export const Pane = styled.div`
    background: black;
    opacity: .9;
    border-radius: 4px;
    font-size: 14px;
    margin: 0 auto 0 auto;
    color: #f8f8ff;
    padding: 2em;
    width: 70%;
    height: 45%;
    z-index: 2;

    @media (max-width: 1000px) and (orientation: landscape){
      height: 70%;
    }
    @media (min-width: 750px) {
      height: 45%;
    }
    
    @media (min-width: 1000px) {
      height: 40%;
    }
    
    @media (min-width: 1200px) {
      height: 70%;
    }

    @media (min-width: 1300px) {
      height: 50%;
    }

    @media (min-width: 1400px) {
      height: 75%;
    }

    @media (min-width: 1900px) {
      height: 55%;
    }

    @media (min-width: 2000px) and (orientation: landscape){
      height: 60%;
    }

`;


export const Title = styled.h2`
    opacity: 1;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 28px;

    @media (min-width: 750px) {
      font-size: 2rem
    }

    @media (min-width: 1000px){
      font-size: 2.3rem;
    }

    @media (min-width: 1400px){
      font-size: 2.3rem;
    }

    @media (min-width: 1500px){
      font-size: 3rem;
    }

    @media (min-width: 2000px){
      font-size: 4.5rem;
    }
`;

export const Info = styled.section`
margin-bottom: 2em;

@media (min-width: 750px) {
  margin-bottom: 3em;
}

@media (min-width: 1500px){
    margin-bottom: 5em;
}

@media (min-width: 2000px){
    margin-bottom: 10em;
}
`

export const LogoPane = styled.div`
    width: 50%;
    margin: 0 auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1000px) and (orientation: landscape){
      width: 40%;
    }

    @media (min-width: 750px) {
      margin: 0 auto 0 auto;
    }

    @media (min-width: 1500px){
        width: 40%;
        height: 10%;
        margin: 0 auto 0 auto;
    }

    @media (min-width: 2000px){
        width: 30%;
        height: 10%;
        margin: 0 auto 0 auto;
    }
`;


export const LogoCont = styled.div`
    object-fit: cover;
    width: 15%;
    cursor: pointer;

    &:hover{
      width: 18%;
    }

`;

export const Logos = styled.img`
  object-fit: cover;
  width: 100%;
`;
