import React from 'react';
import {Inner, Item, Container, Title, Pane, Section, SubTitle, List, LI, ImgCont, Img, Ltext } from './styles/highlights';


export default function Highlights({ children, ...restProps }) {

    return (
        <Item {...restProps}>
            <Inner>{children}</Inner>
        </Item>
    )
}

Highlights.Container = function HighlightsContainer({ children, ...restProps }) {
      return <Container {...restProps}>{children}</Container>;
  }
  
Highlights.Title = function HighlightsTitle({ children, ...restProps }) {
    return <Title {...restProps}>{children}</Title>;
}
  
Highlights.Pane = function HighlightsPane({ children, ...restProps }) {
    return <Pane {...restProps}>{children}</Pane>;
}

Highlights.Section = function HighlightsSection({ children, ...restProps }) {
    return <Section {...restProps}>{children}</Section>;
}

Highlights.SubTitle = function HighlightsSubTitle({ children, ...restProps }) {
    return <SubTitle {...restProps}>{children}</SubTitle>;
}

Highlights.List = function HighlightsList({ children, ...restProps }) {
    return <List {...restProps}>{children}</List>;
}

Highlights.LI = function HighlightsLI({ children, ...restProps }) {
    return <LI {...restProps}>{children}</LI>;
}

Highlights.ImgCont = function HighlightsImgCont({ children, ...restProps }) {
    return <ImgCont {...restProps}>{children}</ImgCont>;
}

Highlights.Img = function HighlightsImg({ children, ...restProps }) {
    return <Img {...restProps}>{children}</Img>;
}

Highlights.Ltext = function HighlightsLtext({ children, ...restProps }) {
    return <Ltext {...restProps}>{children}</Ltext>;
}

