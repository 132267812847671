import React, { useState, useEffect, useContext} from 'react';
import { Highlights } from '../components';
import { CSSTransition } from "react-transition-group";
import { Context } from '../context';

export function HighlightsContainer() {
  
  const { setHeight } = useContext(Context)
  const [fadeIn, setFadeIn] = useState(false)

  useEffect((fadeIn) => {
    setFadeIn(!fadeIn)
    setHeight(`${document.getElementById('highCont').offsetHeight}px`)
  }, [setFadeIn, fadeIn, setHeight])

  return (
    <CSSTransition
    in={fadeIn}
    classNames={'fade'}
    timeout={800}
  >
      <Highlights.Container id="highCont">
            <Highlights.Title>Selected Highlights to date</Highlights.Title>

            <Highlights.Pane>

                <Highlights.Section>
                <Highlights.SubTitle>Theatre</Highlights.SubTitle>
                <Highlights.List>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Priscilla Queen of the Desert UK Tour 2019/20</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>American Idiot 10th Anniversary tour 2019</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Madagascar UK tour 2018/19</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Hairspray UK and Ireland tour 2017/18</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                            <Highlights.Ltext>Hairspray UK tour 2015/16</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Regents Park Open air Theatre 2014-20</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Longborough Festival Opera 2017-2019</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Wizard of Oz, Blackpool Winter Gardens 2018</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>York Mystery plays, York Minster</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Let it be UK tour - additional rigging requirements</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Fame UK tour - additional rigging requirements</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/square.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Avenue Q UK tour - additional rigging requirements</Highlights.Ltext>
                        </Highlights.LI>
                </ Highlights.List>
                </Highlights.Section>

              <Highlights.Section>
                    <Highlights.SubTitle>TV and Film</Highlights.SubTitle>
                    <Highlights.List>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Mission Impossible 6 2017 (location)</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Hobbs and Shaw 2018 (location)</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>The Jumbo revolution 747 documentary</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Prized Apart - series 1</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Churchill tv advert</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Santander bank Lego advert</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Sky Sports Fight Night boxing advert</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>My Favourite sketch - series 1</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Royal Variety Performance - drape rigging</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Children In Need - after show</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Strictly Come Dancing - Celeb reveal</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/triangle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>British Soap Awards - red carpet</Highlights.Ltext>
                        </Highlights.LI>
                    </ Highlights.List>
                    </Highlights.Section>

                

            </Highlights.Pane>

            <Highlights.Pane>

                    

              <Highlights.Section>
                    <Highlights.SubTitle>Conference and Other</Highlights.SubTitle>
                    < Highlights.List>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Mercedes conference 02 intercontinental</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>McDonalds Annual UK conference and awards dinner</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Roche conference, 02 Victoria Warehouse</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>An Evening with Conor McGregor, EventCity</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Sennheiser 70th Anniversary, Marylebone One</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Travel Councellors Annual conference and awards dinner</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>MSC Bellissima cruise ship launch</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Kingfisher B and Q long-term training centre</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>ILM Disney, green screen studio</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Biophillia Ball, Natural History Museum</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Winter Wonderland, Manchester</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Ford Launch, Imagination, Here East</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Dinosaurs in the Wild, EventCity</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Ideal Home show, Manchester</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Princess yacht stand Boat Show, Excel</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>George Orwell statue reveal, BBC Broadcasting house</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Opera Exhibition, V and A Museum</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>World Snooker Championships, EventCity</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Samsung Galaxy Phone Global Launch, Here East</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Liberty’s store Christmas window reveal</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Outdoor Drone racing course, Farnborough International Air Tattoo</Highlights.Ltext>
                        </Highlights.LI>
                        <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/diamond.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>BAE fighter jet, Farnborough Five</Highlights.Ltext>
                        </Highlights.LI>
                    </ Highlights.List>
                    </Highlights.Section>

                <Highlights.Section>
                <Highlights.SubTitle>Arena</Highlights.SubTitle>
                < Highlights.List>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>David Icke Wembley Arena</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>UBT Wembley Arena</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>BBC Worldwide Showcase Liverpool Echo arena 2016/17/18</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Brent makes music Wembley Arena</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>TFC 20th anniversary concert Wembley Arena</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>The Slavi show Wembley Arena</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>Richer View conference 02 Arena</Highlights.Ltext>
                        </Highlights.LI>
                      <Highlights.LI>
                          <Highlights.ImgCont>
                              <Highlights.Img src={process.env.PUBLIC_URL + "content/circle.jpg"} alt="b&W city picture" />
                          </ Highlights.ImgCont>
                          <Highlights.Ltext>OMD production rehearsals Studio 7</Highlights.Ltext>
                        </Highlights.LI>
                </ Highlights.List>
                </Highlights.Section>     

            </Highlights.Pane>


      </Highlights.Container>
    </CSSTransition>
  )
}