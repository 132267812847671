import styled from 'styled-components';

export const Item = styled.div`
`;

export const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    max-width: 1500px;
    margin: auto;
    width: 100%;

    @media (max-width: 1250px) {
        flex-direction: column;
    }
`;

export const Pane = styled.div`
    width: 100%;
    object-fit: cover;
    text-align: center;
    margin-top: 1em;
    border: 2px solid white;
`;

export const Container = styled.section`
padding: 0 .3rem 0 .3rem;
height: ${({height}) => height};
overflow: hidden;
`;

export const Img = styled.img`
    width: 100%;
    height: auto;
    margin-top: 0;
    padding-top: 0;

`;
