import React, { useContext, useEffect } from 'react';
import { NavContainer } from '../containers/nav';
import { ContactContainer } from '../containers/contact';
import { Grid } from '../components';
import { Context } from '../context';

export default function Home({location}) {

const { setAbout, setHome, setContact, setServ } = useContext(Context)

  useEffect(() => {
    setContact(true)
    setHome(false)
    setServ(false)
    setAbout(false)
  }, [setContact, setHome, setServ, setAbout])


    return (
      <Grid.Container2>
          <Grid.Nav>
            <NavContainer />
          </ Grid.Nav>
          <Grid.Content>
            <ContactContainer />
          </ Grid.Content>
      </ Grid.Container2>
      )
}
